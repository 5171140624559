<template>
    <header class="login">
        <van-image
            class="login_logo"
            width="100"
            height="100"
            :src="require('../../assets/img/login_logo.png')"
        />
        <div class="login_title">工时管家</div>
        <div class="login_subtitle">您好，您购买的版本已到期!</div>
        <p class="expire_text">
            尊敬的用户! 您购买的版本[{{versionList[versionId]}}] 已到期(到期日期{{expDate}})
            ，若需继续使用请前往企业微信应用市场续费，给贵公司带来不便，敬请谅解!
        </p>
        <div class="expire_service">
            <p>扫码加客服微信</p>
            <van-image
                class="kf"
                width="240"
                :src="require('../../assets/img/kf.jpg')"
            />
            <!-- <p>
                <span style="color: #333">QQ：</span
                ><span id="QQ">3052894409</span>
            </p> -->
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            versionId: 0,
            versionList: ["verson","基础版", "专业版", "旗舰版", "建筑工程版"],
            expDate: "date",
        };
    },
    mounted() {
        this.versionId = parseInt(
            window.location.href.split("version=")[1].split("&")[0]
        );
        this.expDate = window.location.href.split("expDate=")[1].split('#')[0];
    },
};
</script>

<style lang="less" scoped>
.login {
    height: 100vh;
    background-color: #fff;
}
header {
    text-align: center;
}
.login_logo {
    margin: 55px 0 20px;
}
.login_title {
    font-size: 20px;
    color: #505458;
    margin: 0 0 10px 0;
    font-weight: bold;
}
.login_subtitle {
    font-size: 18px;
    color: #20a0ff;
    margin: 20px 0 40px 0;
}
.expire_text {
    font-size: 15px;
    color: #606266;
    line-height: 24px;
    margin: 20px 0 40px 0;
}
.expire_service {
    padding: 10px;
    p {
        font-size: 14px;
        margin-bottom: 10px;
        color: #999;
    }
}
</style>